import { observer } from "mobx-react-lite";
import { ReactElement, useContext, useEffect, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Context } from ".";
import Header from "./components/Header";
import LoginForm from "./components/LoginForm";
import RegisterForm from "./components/RegisterForm";
import Toast from "./components/Toast";
import AddControlGroup from "./pages/AddControlGroup";
import AddDepartment from "./pages/AddDepartment";
import AddPosition from "./pages/AddPosition";
import AddStateHoliday from "./pages/AddStateHoliday";
import AddUsers from "./pages/AddUsers";
import ControlGroup from "./pages/ControlGroup";
import ControlGroups from "./pages/ControlGroups";
import DepartmentPage from "./pages/DepartmentPage";
import Departments from "./pages/Departments";
import EditAvailableDays from "./pages/EditAvailableDays";
import EditControlGroup from "./pages/EditControlGroup";
import EditDepartment from "./pages/EditDepartment";
import EditPositions from "./pages/EditPositions";
import EditUser from "./pages/EditUser";
import GeneralTable from "./pages/GeneralTable";
import Holiday from "./pages/Holiday";
import HolidayForUser from "./pages/HolidayForUser";
import Mainpage from "./pages/Mainpage";
import NoAccess from "./pages/NoAccess";
import PeriodPage from "./pages/PeriodPage";
import Position from "./pages/Position";
import Positions from "./pages/Positions";
import Profile from "./pages/Profile";
import RecoveryPassword from "./pages/RecoveryPassword";
import ResetPassword from "./pages/ResetPassword";
import StateHolidays from "./pages/StateHolidays";
import UserPage from "./pages/UserPage";
import UsersPage from "./pages/UsersPage";
import Spinner from "./components/Spinner";

interface ProtectedRouteProps {
  isAllowed: boolean;
  redirectUrl: string;
  children?: ReactElement;
  loading: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isAllowed,
  redirectUrl,
  children,
  loading,
}) => {
  if (loading) {
    return <Spinner />; // Или любой другой компонент загрузки
  }
  if (!isAllowed) {
    return <Navigate to={redirectUrl} />;
  }
  return children || <Outlet />;
};

function App() {
  const { store } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  // const [users, setUsers] = useState<IUser[]>([])

  useEffect(() => {
    if (localStorage.getItem("token")) {
      store.checkAuth().then(() => {
        setIsLoading(false); // Обновляем состояние загрузки после завершения аутентификации
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  // async function getUsers() {
  //   try {
  //     const response = await UserService.fetchUsers();
  //     setUsers(response.data)
  //   } catch (e) {
  //     console.log(e);

  //   }
  // }

  // if (store.isLoading) {
  //   return <Spinner />
  // }

  // if (!store.isAuth) {
  //   return <LoginForm />;
  // }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <div>
              <Toast />
              <Header />
              <Outlet />
              {/* <Footer /> */}
            </div>
          }
        >
          <Route path="/" element={<Mainpage />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/register" element={<RegisterForm />} />
          <Route path="/departments/:id" element={<DepartmentPage />} />
          <Route path="/periods/:id" element={<PeriodPage />} />
          <Route path="/periods/edit/:id" element={<EditAvailableDays />} />
          <Route
            path="/users"
            element={
              <ProtectedRoute
                loading={isLoading}
                isAllowed={store.user.roles?.[0] === "ADMIN"}
                redirectUrl="/"
              >
                <UsersPage />
              </ProtectedRoute>
            }
          />
          <Route path="/edit/:id" element={<EditUser />} />
          <Route path="/user/:id" element={<UserPage />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/holidays/chooseholiday" element={<Holiday />} />
          <Route path="/recovery" element={<RecoveryPassword />} />
          <Route path="/reset-password/:id" element={<ResetPassword />} />
          <Route
            path="/holidays/chooseholiday/:id"
            element={<HolidayForUser />}
          />
          <Route
            path="/controlGroups"
            element={
              <ProtectedRoute
                loading={isLoading}
                isAllowed={store.user.roles?.[0] === "ADMIN"}
                redirectUrl="/"
              >
                <ControlGroups />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users/createUsers"
            element={
              <ProtectedRoute
                loading={isLoading}
                isAllowed={store.user.roles?.[0] === "ADMIN"}
                redirectUrl="/"
              >
                <AddUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/controlGroups/profile/:id"
            element={
              <ProtectedRoute
                loading={isLoading}
                isAllowed={store.user.roles?.[0] === "ADMIN"}
                redirectUrl="/"
              >
                <ControlGroup />
              </ProtectedRoute>
            }
          />
          <Route path="/stateHolidays" element={<StateHolidays />} />
          <Route
            path="/stateHolidays/addStateHoliday"
            element={<AddStateHoliday />}
          />
          <Route
            path="/controlGroups/addControlGroup"
            element={<AddControlGroup />}
          />
          <Route path="/home/generalTable" element={<GeneralTable />} />
          <Route
            path="/departments"
            element={

                <Departments />

            }
          />
          <Route
            path="/departments/createDepartment"
            element={<AddDepartment />}
          />
          <Route path="/departments/edit/:id" element={<EditDepartment />} />
          <Route
            path="/controlGroups/edit/:id"
            element={<EditControlGroup />}
          />
          <Route
            path="/positions"
            element={

                <Positions />
       
            }
          />
          <Route path="/positions/createPosition" element={<AddPosition />} />
          <Route path="/positions/edit/:id" element={<EditPositions />} />
          <Route path="/positions/:id" element={<Position />} />
          <Route path="/no-access" element={<NoAccess />} />
        </Route>
      </Routes>
    </BrowserRouter>
    // <div>
    //   <div className="App">
    //     <h1>
    //       {store.isAuth
    //         ? `Пользователь авторизован ${store.user.email}`
    //         : "Авторизуйтесь"}
    //     </h1>
    //     <h1>
    //       {store.user.isActivated
    //         ? `Ваш аккаунт подтвержден по почте`
    //         : "Подтвердите аккаунт"}
    //     </h1>
    //     <button onClick={() => store.logout()}>Выйти</button>
    //     <div>
    //       <button onClick={getUsers}>Получить пользователей</button>
    //     </div>
    //     {users.map((user) => (
    //       <div key={user?._id}>
    //         {user?.name} {user?.surname}
    //       </div>
    //     ))}
    //   </div>
    //   ;
    // </div>
  );
}

export default observer(App);

{
  /* <div className="App">
  <h1>
    {store.isAuth
      ? `Пользователь авторизован ${store.user.email}`
      : "Авторизуйтесь"}
  </h1>
  <h1>
    {store.user.isActivated
      ? `Ваш аккаунт подтвержден по почте`
      : "Подтвердите аккаунт"}
  </h1>
  <button onClick={() => store.logout()}>Выйти</button>
  <div>
    <button onClick={getUsers}>Получить пользователей</button>
  </div>
  {users.map((user) => (
    <div key={user?.id}>
      {user?.name} {user?.surname}
    </div>
  ))}
</div>; */
}
