import React, { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { Context } from "..";
import { IStateHoliday } from "../models/IStateHoliday";
import { Link, useNavigate } from "react-router-dom";
const initialValue: IStateHoliday = {
  startDate: "",
  endDate: "",
  title: "",
};
const AddStateHoliday = () => {
  const { store } = useContext(Context);
  const [value, setValue] = useState<IStateHoliday>(initialValue);
  const navigate = useNavigate();
  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    store.addStateHoliday(value);

    setTimeout(() => {
      navigate("/stateHolidays");
    }, 1000);
  };

  const changeHandler = (fields: Partial<IStateHoliday>) => {
    setValue((prev) => {
      return { ...prev, ...fields };
    });
  };

  return (
    <div className="container">
      <form onSubmit={submitHandler}>
        <Form.FloatingLabel
          label="Название праздника"
          className="mb-2"
          controlId="floatingInput"
        >
          <Form.Control
            name="title"
            onChange={(e) => {
              changeHandler({ title: e.target.value });
            }}
            required={true}
            type="text"
          />
        </Form.FloatingLabel>
        <Form.FloatingLabel
          label="Дата начала праздника"
          className="mb-2"
          controlId="floatingInput"
        >
          <Form.Control
            onChange={(e) => {
              changeHandler({ startDate: e.target.value });
            }}
            name="startDate"
            required={true}
            type="date"
          />
        </Form.FloatingLabel>
        <Form.FloatingLabel
          label="Дата окончания праздника"
          className="mb-2"
          controlId="floatingInput"
        >
          <Form.Control
            onChange={(e) => {
              changeHandler({ endDate: e.target.value });
            }}
            name="endDate"
            required={true}
            type="date"
          />
        </Form.FloatingLabel>
        <button type="submit" className="btn btn-primary btn-block me-2 my-2">
          Подтвердить
        </button>
        <Link className="btn btn-secondary" to="/stateHolidays">
          Отмена
        </Link>
      </form>
    </div>
  );
};

export default AddStateHoliday;
