import React, {useContext, useState, useEffect} from 'react';
import { IDepartment } from '../models/IDepartment';
import { Context } from '..';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { IPosition } from '../models/IPosition';
import { observer } from 'mobx-react-lite';

const AddPosition = () => {
  const { store } = useContext(Context);
  const [value, setValue] = useState<IPosition>({ title: "" });
  const navigate = useNavigate();

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    store.addPosition(value, navigate)


  };

  return (
    <div className="container">
      <h2>Добавление отдела</h2>
      <form onSubmit={submitHandler}>
        <Form.FloatingLabel
          label="Отдел"
          className="mb-2"
          controlId="floatingInput"
        >
          <Form.Control
            onChange={(e) => {
              setValue({ title: e.target.value });
            }}
            name="title"
            required={true}
            type="text"
          />
        </Form.FloatingLabel>
        <button type="submit" className="btn btn-primary btn-block me-2 my-2">
          Подтвердить
        </button>
        <Link
          className="btn btn-secondary"
          to="/positions"
        >
          Отмена
        </Link>
      </form>
    </div>
  )
};

export default observer(AddPosition);