import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { Context } from "..";
import { useNavigate } from "react-router-dom";

const AddControlGroup = () => {
  const { store } = useContext(Context);
  const [value, setValue] = useState<object>({title:""});
  const navigate = useNavigate(); 

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    store.addControlGroup(value);

    setTimeout(() => {
      navigate("/controlGroups");
    }, 1000);
  };

  return (
    <div className="container">
      <h2>Создание контрольной группы</h2>
      <form onSubmit={submitHandler}>
        <Form.FloatingLabel
          label="Контрольная группа"
          className="mb-2"
          controlId="floatingInput"
        >
          <Form.Control
            onChange={(e) => {
              setValue({ title: e.target.value });
            }}
            name="title"
            required={true}
            type="text"
          />
        </Form.FloatingLabel>
        <button type="submit" className="btn btn-primary btn-block me-2 my-2">
          Подтвердить
        </button>
        <a
          className="btn btn-secondary"
          href="/controlGroups"
        >
          Отмена
        </a>
      </form>
    </div>
  );
};

export default observer(AddControlGroup);
