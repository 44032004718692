import React, { FC, useContext, useState } from "react";
import { Context } from "../index";
import { observer } from "mobx-react-lite";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "./Spinner";
import { useNavigate } from "react-router-dom";
const LoginForm: FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { store } = useContext(Context);
  const navigate = useNavigate()
  if (store.isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="d-flex justify-content-center">
        <div className="border rounded mb-4 custom-forms mt-5 mb-0 p-5">
          <div className="mx-auto my-2 text-center">
            <h1>Программа отпусков</h1>
          </div>
          <h6 className="text-secondary mt-2 mb-4 mx-auto text-center">
            Войдите, чтобы использовать полный функционал приложения
          </h6>
          <form onSubmit={() => store.login(email, password, navigate)}>
            <Form.FloatingLabel
              label="Email"
              className="mb-3"
              controlId="floatingInput"
            >
              <Form.Control
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                value={email}
              />
            </Form.FloatingLabel>

            <Form.FloatingLabel
              label="Пароль"
              className="mb-3"
              controlId="floatingInput"
            >
              <Form.Control
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
              />
            </Form.FloatingLabel>
            <Button variant="primary" type="submit">
              Войти
            </Button>
          </form>
        </div>
      </div>
      <div className="d-flex justify-content-center my-0">
        <div className="border rounded my-0 custom-forms p-3">
          <div className="mx-auto my-2 text-center">
            <p>
              У вас ещё нет аккаунта? <a href="/register">Зарегистрироваться</a>
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center my-4">
        <div className="border rounded my-0 custom-forms p-3">
          <div className="mx-auto my-2 text-center">
            <p>
              Забыли пароль? <a href="/recovery">Восстановить аккаунт</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(LoginForm);

// <div>
//   <div className="d-flex justify-content-center">
//     <div className="border rounded mb-4 custom-forms mt-5 mb-0 p-5">
//       <div className="mx-auto my-2 text-center">
//         <h1>Программа отпусков</h1>
//       </div>
//       <h6 className="text-secondary mt-2 mb-4 mx-auto text-center">
//         Войдите, чтобы использовать полный функционал приложения
//       </h6>
//       <Form>
//         <Form.FloatingLabel
//           label="Email"
//           className="mb-3"
//           controlId="floatingInput"
//         >
//           <Form.Control
//             onChange={(e) => setEmail(e.target.value)}
//             type="email"
//             value={email}
//           />
//         </Form.FloatingLabel>

//         <Form.FloatingLabel
//           label="Пароль"
//           className="mb-3"
//           controlId="floatingInput"
//         >
//           <Form.Control
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             type="password"
//           />
//         </Form.FloatingLabel>
//         <Button
//           onClick={() => store.login(email, password)}
//           variant="primary"
//           type="submit"
//         >
//           Войти
//         </Button>
//       </Form>
//     </div>
//   </div>
//   <div className="d-flex justify-content-center my-0">
//     <div className="border rounded my-0 custom-forms p-3">
//       <div className="mx-auto my-2 text-center">
//         <p>
//           У вас ещё нет аккаунта? <a href="/register">Зарегистрироваться</a>
//         </p>
//       </div>
//     </div>
//   </div>
// </div>;
