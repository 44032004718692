/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { IUser } from "../models/IUser";
import UserService from "../services/UserService";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Context } from "..";
import { observer } from "mobx-react-lite";
import { IDepartment } from "../models/IDepartment";
import DepartmentService from "../services/DepartmentService";
import PositionService from "../services/PositionService";
import { IPosition } from "../models/IPosition";
import Placeholder from "../components/Placeholder";
import { toast } from "react-toastify";
const initialUser: IUser = {
  name: "",
  surname: "",
  email: "",
  _id: "",
  isLeader: false,
  isActivated: false,
  department: { title: "", _id: "" },
  position: { title: "", _id: "" },
  employmentDate: "",
  employmentDateChanged: false,
  holidays: [],
};
const EditUser = () => {
  const [user, setUser] = useState<IUser>(initialUser);
  const [department, setDepartment] = useState<IDepartment[]>([]);
  const [position, setPosition] = useState<IPosition[]>([]);
  const [value, setValue] = useState({ name: "", surname: "" });
  const { id } = useParams();
  const { store } = useContext(Context);
  const navigate = useNavigate();
  const [maxDate, setMaxDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  useEffect(() => {
    const fetchUser = async () => {
      if (id) {
        const response = await UserService.fetchUser(id);
        setUser(response.data);

        setValue({ name: response.data.name, surname: response.data.surname });
      }
      const response = await DepartmentService.fetchDepartment();
      setDepartment(response.data);
      const position = await PositionService.fetchPosition();
      setPosition(position.data);
    };

    fetchUser();
  }, []);

  const changeHandler = (fields: Partial<IUser>) => {
    setUser((prev) => {
      return { ...prev, ...fields };
    });
  };

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (window.confirm("Вы уверены, что хотите сохранить изменения?")) {
      store.editUsers(user, user._id).then(() => {
        navigate("/users");
      });
    } else {
      toast.warning("Изменения отменены пользователем.");
    }
  };
  if (store.isLoading) {
    return (
      <div className="">
        <div className="container">
          <h2>
            <Placeholder />
          </h2>
          <form>
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <div className="container">
        <h2>
          {value?.name} {value?.surname}
        </h2>
        <form onSubmit={submitHandler}>
          <Form.FloatingLabel
            label="Email"
            className="mb-2"
            controlId="floatingInput"
          >
            <Form.Control
              value={user?.email}
              required={true}
              type="email"
              disabled
              placeholder="Email"
            />
          </Form.FloatingLabel>
          <Form.FloatingLabel
            label="Ваше имя"
            className="mb-2"
            controlId="floatingInput"
          >
            <Form.Control
              onChange={(e) => changeHandler({ name: e.target.value })}
              value={user?.name}
              name="name"
              required={true}
              type="text"
            />
          </Form.FloatingLabel>
          <Form.FloatingLabel
            label="Ваша фамилия"
            className="mb-2"
            controlId="floatingInput"
          >
            <Form.Control
              onChange={(e) => changeHandler({ surname: e.target.value })}
              required={true}
              type="text"
              value={user?.surname}
            />
          </Form.FloatingLabel>
          <Form.Select
            required
            value={user?.department?.title}
            onChange={(e) =>
              changeHandler({ department: { _id: e.target.value } })
            }
            className="mb-3"
            aria-label="Default select example"
          >
            <option selected disabled hidden>
              {user?.department?.title}
            </option>
            {department.map((item) => (
              <option key={item._id} value={item._id}>
                {item?.title}
              </option>
            ))}
          </Form.Select>
          <Form.Select
            required
            onChange={(e) =>
              changeHandler({ position: { _id: e.target.value } })
            }
            className="mb-3"
            aria-label="Default select example"
          >
            <option selected disabled hidden>
              {user?.position?.title}
            </option>
            {position.map((item) => (
              <option key={item._id} value={item._id}>
                {item?.title}
              </option>
            ))}
          </Form.Select>
          <Form.Check
            type={"checkbox"}
            label={`Руководитель`}
            id={`default-checkbox`}
            className="mb-2"
            checked={user?.isLeader}
            onChange={(e) => changeHandler({ isLeader: e.target.checked })}
          />
          <Form.FloatingLabel
            label="Дата трудоустройства"
            className="mb-2"
            controlId="floatingInput"
          >
            <Form.Control
              onChange={(e) =>
                changeHandler({ employmentDate: e.target.value })
              }
              max={maxDate}
              required={true}
              value={user.employmentDate.split("T")[0]}
              type="date"
              name="employmentDate"
            />
          </Form.FloatingLabel>
          {/* <p className="mb-2 text-danger">
            {user.employmentDateChanged
              ? "Вы уже изменили дату !"
              : "Дату трудоустройства можно изменить только один раз !"}
          </p> */}
          <button type="submit" className="btn btn-primary btn-block">
            Подтвердить
          </button>
          <Link to="/users" className="btn btn-secondary ms-1">
            Отмена
          </Link>
        </form>
      </div>
    </div>
  );
};

export default observer(EditUser);
