import React from 'react';
import { IUser } from '../models/IUser';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Button } from 'react-bootstrap';

type UserTableProps = {
  users: IUser[];
  admin?: IUser;
  deleteUser?: any;
};

const UserTable: React.FC<UserTableProps> = ({ users, deleteUser, admin }) => {
  console.log(admin?.roles?.includes("ADMIN"));
  
  return (
    <table id="users-table" className="table table-striped table-hover">
      <tbody>
        <tr>
          <th>Фамилия</th>
          <th>Имя</th>
          <th>Email</th>
          <th>Управление</th>
        </tr>
        {users?.map((user) => (
          <tr key={user._id}>
            <td>
              <Link to={`/user/${user._id}`} className="link-primary">
                {user.surname}
              </Link>
            </td>
            <td>
              <Link to={`/user/${user._id}`} className="link-primary">
                {user.name}
              </Link>
            </td>
            <td
              className={
                user.employmentDateChanged ? "text-success" : "text-danger"
              }
            >
              {user.email}
            </td>
            <td>
              <Link to={`/edit/${user._id}`} className="btn bg-warning">
                Редактировать
              </Link>
              {admin?.roles?.includes("ADMIN") &&
                user.roles?.[0] !== "ADMIN" && (
                  <Button
                    onClick={() => {
                      deleteUser(user._id);
                    }}
                    className="mx-2 btn btn-danger"
                  >
                    Удалить
                  </Button>
                )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UserTable