import React, { useContext, useEffect, useState } from "react";
import { IUser } from "../models/IUser";
import { Context } from "..";
import { Link, useNavigate, useParams } from "react-router-dom";
import UserService from "../services/UserService";
import { toast } from "react-toastify";

import ProfileTable from "../components/ProfileTable";
import { observer } from "mobx-react-lite";
import Spinner from "../components/Spinner";
const Profile = () => {
  const { store } = useContext(Context);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (id) {
      store.fetchUser(id);
    }
  }, [id, store, store.oneuser?.employmentDateChanged]);

  const deleteHoliday = async (id: string) => {
    if (window.confirm("Вы уверены, что хотите удалить данный отпуск?")) {
      await store.deleteHolidays(id);
    } else {
      toast.error("Вы отменили удаление");
    }
  };

  if (store.isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {store.oneuser?.employmentDateChanged ? (
        <div className="pb-4">
          <div className="container">
            <h2>
              {store.oneuser?.name} {store.oneuser?.surname}
            </h2>
            <p>{store.oneuser?.position?.title}</p>
            <ProfileTable user={store.oneuser} deleteHoliday={deleteHoliday} />
            <Link className="btn btn-primary" to={`/holidays/chooseholiday`}>
              Выбрать отпуск
            </Link>
          </div>
        </div>
      ) : (
        <div className="container">
          <h2>Ваш профиль находится на модерации и скоро появится</h2>
        </div>
      )}
    </>
  );
};

export default observer(Profile);
