import React, { useContext, useEffect } from "react";
import { Context } from "..";
import Spinner from "../components/Spinner";
import { observer } from "mobx-react-lite";

const StateHolidays = () => {
  const { store } = useContext(Context);

  useEffect(() => {
    store.getStateHolidays();
  }, []);

  const deleteStateHolidays = (id?: string) => {
    store.deleteStateHoliday(id);
  };

  if (store.isLoading) {
    return <Spinner />;
  }

  return (
    <div className="container">
      <h2>Государственные праздники</h2>
      <table id="users-table" className="table table-striped">
        <tbody>
          <tr>
            <th>№</th>
            <th>Название</th>
            <th>Дата начала</th>
            <th>Дата окончания</th>
            <th>Управление</th>
          </tr>
          {store.stateHolidays.map((el, index) => (
            <tr key={el._id}>
              <th>{index + 1}</th>
              <td>{el.title}</td>
              <td>{store.formatDateDDMMYYYY(el.startDate)}</td>
              <td> {store.formatDateDDMMYYYY(el.endDate)}</td>
              <td>
                <a
                  className="btn btn-warning mx-2"
                  href={`/stateHolidays/edit/${el._id}`}
                >
                  Редактировать
                </a>
                <a
                  onClick={() => {
                    deleteStateHolidays(el?._id);
                  }}
                  className="btn btn-danger"
                  href="/stateHolidays"
                >
                  Удалить
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <a className="btn btn-primary" href="/stateHolidays/addStateHoliday">
        Добавить праздник
      </a>
    </div>
  );
};

export default observer(StateHolidays);
