import $api from "../http";
import { AxiosResponse } from "axios";
import { AuthResponse } from "../models/response/AuthResponse";
import { IUser } from "../models/IUser";
export default class AuthService {
  static async login(
    email: string,
    password: string
  ): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>("login", { email, password });
  }

  static async registration(
    email: string,
    password: string,
    name: string,
    surname: string,
    position: string,
    department: string
  ): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>("registration", { email, password, name, surname, position, department});
  }

  static async logout(): Promise<void> {
    return $api.post("logout");
  }

  static async changePassword(userId: string, oldPassword: string, newPassword: string): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>("user/changePassword", { userId, oldPassword, newPassword });
  }
}
