import { DateSelectArg, DayCellContentArg } from "@fullcalendar/core";
import ruLocale from "@fullcalendar/core/locales/ru";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";
import FullCalendar from "@fullcalendar/react";
import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Context } from "..";
import { IHolidays } from "../models/IHolidays";
import { Link, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { IUser } from "../models/IUser";
import UserService from "../services/UserService";
const initialValue: IHolidays = {
  dateFrom: "",
  dateUntil: "",
  periodId: "",
  userId: "",
};

interface IEvent {
  start: string;
  end: string;
  title?: string;
  backgroundColor: string;
  editable: boolean;
  display: string;
  className: string;
}

const HolidayForUser = () => {
  const { store } = useContext(Context);
  const [value, setValue] = useState<IHolidays>(initialValue);
  const [calendarEvents, setCalendarEvents] = useState<IEvent[]>([]);
  const [user, setUser] = useState<IUser>();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (id) {
          const response = await UserService.fetchUser(id);
          setUser(response.data);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    if (user?.department?._id) {
      store.getDepartmentById(user?.department?._id);
    }
  }, [user?.department?._id]);

  useEffect(() => {
    if (user?.department?._id) {
      store.getHolidaysById(user?.department._id).then(() => {
        const events: IEvent[] = store.userHolidays.map((holiday) => ({
          start: holiday.dateFrom,
          end: holiday.dateUntil,
          title:
            user?._id === holiday.userId
              ? `Запланированный отпуск ${holiday.name}`
              : `Отпуск сотрудника ${holiday.name}`,
          backgroundColor: user?._id === holiday.userId ? "#2eb62c" : "#ffcc00",
          editable: false,
          display: "auto",
          className: "fullcalendar",
        }));
        setCalendarEvents(events);
      });
    }
  }, [store, user?._id, user?.department?._id]);
  const handleDateSelect = (selectInfo: DateSelectArg) => {
    let calendarApi = selectInfo.view.calendar;
    setValue({
      dateUntil: selectInfo.endStr,
      dateFrom: selectInfo.startStr,
      userId: id,
    });
    calendarApi.unselect();
  };
  console.log(value);

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    store.createHolidays(value).then(() => {
      navigate(`/users`);
    });
  };

  const getDayCellClassNames = (arg: DayCellContentArg) => {
    const currentDate = new Date(arg.date);
    currentDate.setHours(0, 0, 0, 0);
    const date = arg.date;
    const classNames = ["fullcalendar"];
    const isRestricted = store.onedepartment.restrictedHolidays?.some(
      (holiday) => {
        const fromDate = new Date(holiday.from);
        fromDate.setHours(0, 0, 0, 0);
        const toDate = new Date(holiday.to);
        toDate.setHours(23, 59, 59, 999);

        return date >= fromDate && date <= toDate;
      }
    );

    if (isRestricted) {
      classNames.push("red-day"); // Класс для ограниченных дат
      arg.isDisabled = true; // Отключение ячейки
    }

    return classNames;
  };

  const changeHandler = (fields: Partial<IHolidays>) => {
    setValue((prev) => {
      return { ...prev, ...fields };
    });
  };

  return (
    <div className="container">
      <h2>Выбрать отпуск</h2>
      <form onSubmit={submitHandler}>
        <div>
          <Form.FloatingLabel
            label="Дата начала отпуска"
            className="mb-2"
            controlId="floatingInput"
          >
            <Form.Control
              onChange={(e) =>
                changeHandler({
                  dateFrom: e.target.value,
                  userId: id,
                })
              }
              // max={maxDate}
              required={true}
              value={value.dateFrom}
              type="date"
              name="dateFrom"
              // disabled={user.employmentDateChanged ? true : false}
            />
          </Form.FloatingLabel>
          <Form.FloatingLabel
            label="Дата окончания отпуска"
            className="mb-2"
            controlId="floatingInput"
          >
            <Form.Control
              onChange={(e) =>
                changeHandler({
                  dateUntil: e.target.value,
                  userId: id,
                })
              }
              value={value.dateUntil}
              required={true}
              type="date"
              name="dateUntil"
            />
          </Form.FloatingLabel>
        </div>
        <button type="submit" className="btn btn-primary btn-block">
          Подтвердить
        </button>
        <Link className="btn btn-secondary mx-2" to="/users">
          Отмена
        </Link>
      </form>
      <h2 className="mt-4">Календарь занятых отпусков</h2>
      <p>Выберите год и Вы сможете увидеть, какие даты еще свободны</p>
      <div>
        <FullCalendar
          events={calendarEvents}
          locale={ruLocale}
          plugins={[multiMonthPlugin, interactionPlugin]}
          initialView="multiMonthYear"
          selectable={true}
          select={handleDateSelect}
          dayCellClassNames={getDayCellClassNames}
        />
      </div>
    </div>
  );
};

export default observer(HolidayForUser);
