import { observer } from "mobx-react-lite";
import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "..";
import { IHolidays } from "../models/IHolidays";
import { Week } from "../models/IWeek";
import { IUser } from "../models/IUser";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
const GeneralTable = () => {
  const { store } = useContext(Context);
  const tableRef = useRef(null);
  const [year, setYear] = useState(new Date().getFullYear() + 1);

  const [monthColspan, setMonthColspan] = useState<any>([]);
  const [weekTitles, setWeekTitles] = useState<Week[]>([]);
  const [showMonth, setShowMonth] = useState<boolean>(false);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const [month, setMonth] = useState(currentMonth);
  const [monthYear, setMonthYear] = useState(currentYear);
  const [monthDays, setMonthDays] = useState([]);
  useEffect(() => {
    store.getGeneralTable();
  }, []);

  useEffect(() => {
    store.renderWeekTitle(year, setWeekTitles, setMonthColspan);
  }, [year]);

  useEffect(() => {
    store.renderMonthDays(monthYear, month, setMonthDays);
  }, [month, monthYear, store]);


  const renderUserWeek = (periods: IHolidays[], weekTitles: Week[]) => {
    return weekTitles.map((week, index) => {
      const { checkStatus, daysRange } = store.checkPeriodInWeek(periods, week);
      console.log(checkStatus && week);
       const specialWeek = store.isMarchOrSeptToJanWeek(week);
      return (
        <td
          key={index}
          className={`vertical ${checkStatus ? "selected colorfy" : "border"} ${
            specialWeek ? "taboo" : ""
          }`}
        >
          {daysRange}
        </td> 
      );
    });
  };

  const renderUserRows = () => {
    return store.generalTable.map((department) => (
      <>
        <tr>
          <th>{department.title}</th>
        </tr>
        {department.users.map((user) => (
          <tr>
            <td>
              {user.name} {user.surname}
            </td>
            {renderUserWeek(user.holidays, weekTitles)}
          </tr>
        ))}
      </>
    ));
  };

  const nextYear = (increment: any) => {
    setYear((currentYear) => currentYear + increment);
  };

  // const nextMonth = (increment: any) => {
  //   setMonth((currentMonth) => currentMonth + increment)
  // }

  const nextMonth = () => {
    setMonth((currentMonth) => {
      if (currentMonth === 11) {
        setMonthYear((currentYear) => currentYear + 1);
        return 0;
      }
      return currentMonth + 1;
    });
  };

  const previousMonth = () => {
    setMonth((currentMonth) => {
      if (currentMonth === 0) {
        setMonthYear((currentYear) => currentYear - 1);
        return 11;
      }
      return currentMonth - 1;
    });
  };

  const submitHandler = () => {
    store.exportToExcel(store.getExportData());
  };

  const renderUserDays = (periods: IHolidays[], monthDays: Date[]) => {
    return monthDays.map((day, index) => {
      // Проверяем, попадает ли день в период отпуска
      const currentDayStart = new Date(day.setHours(0, 0, 0, 0)).getTime();
      const currentDayEnd = new Date(day.setHours(23, 59, 59, 999)).getTime();

      // Проверяем, попадает ли день в период отпуска
      const inHoliday = periods.some((period) => {
        const startPeriod = new Date(period.dateFrom).setHours(0, 0, 0, 0);
        const endPeriod = new Date(period.dateUntil).setHours(23, 59, 59, 999);

        return startPeriod <= currentDayEnd && currentDayStart <= endPeriod;
      });
      const isMarch = day.getMonth() === 2;
      return (
        <td
          key={index}
          className={`${inHoliday ? "selected colorfy" : "border"} ${
            isMarch ? "taboo" : ""
          }`}
        >
          {inHoliday && day.getDate()}
        </td>
      );
    });
  };
  const renderUserRowsForDays = () => {
    return store.generalTable.map((department) => (
      <>
        <tr>
          <th>{department.title}</th>
        </tr>
        {department.users.map((user) => (
          <tr className="day-table">
            <td>
              {user.name} {user.surname}
            </td>
            {renderUserDays(user.holidays, monthDays)}
          </tr>
        ))}
      </>
    ));
  };
  return (
    <>
      <div className="container-fluid mt-5">
        <div className="d-flex">
          <h2 className="me-5 mb-3 text-primary fw-normal">
            Общий график отпусков
          </h2>
          <button
            onClick={submitHandler}
            className="btn btn-success btn-sm me-2"
          >
            Экспорт в Excel
          </button>
        </div>
        <div className="d-flex">
          <p
            className={`${
              showMonth ? "text-secondary" : "text-primary"
            } fs-3 me-5 cursor-pointer`}
            onClick={() => setShowMonth(false)}
          >
            Год
          </p>
          <p
            className={`${
              !showMonth ? "text-secondary" : "text-primary"
            } fs-3 cursor-pointer`}
            onClick={() => setShowMonth(true)}
          >
            Месяц
          </p>
        </div>
        {!showMonth && (
          <>
            <div className="d-flex week-calendar-header mb-3 mt-3 align-items-center">
              <button
                className="btn btn-secondary "
                onClick={() => nextYear(-1)}
              >
                Пред
              </button>
              <h5 className="ms-4 me-4 mb-0 fw-bold fs-4 text-dark">{year}</h5>
              <button className="btn btn-secondary" onClick={() => nextYear(1)}>
                След
              </button>
            </div>
            <div className="week-calendar-container">
              <table
                className="table table-bordered my-4 table-excel"
                ref={tableRef}
              >
                <thead className="table-dark">
                  <tr>
                    <th colSpan={1} rowSpan={2}>
                      Отдел/Сотрудник
                    </th>
                    {monthColspan.map((colspan: number, index: number) => (
                      <th className="" key={index} colSpan={colspan}>
                        {store.getMonthName(index)}
                      </th>
                    ))}
                  </tr>
                  <tr>
                    {weekTitles.map((week: any, index: number) => (
                      <td
                        colSpan={0}
                        rowSpan={0}
                        key={index}
                        className="vertical"
                      >
                        {store.getDateString(week.start)} -{" "}
                        {store.getDateString(week.end)}
                      </td>
                    ))}
                  </tr>
                </thead>

                <tbody>{renderUserRows()}</tbody>
              </table>
            </div>
          </>
        )}
        {showMonth && (
          <>
            <div className="d-flex align-items-center week-calendar-header mb-1 mt-5">
              <button className="btn btn-secondary" onClick={previousMonth}>
                Пред
              </button>
              <h5 className="ms-4 me-4 mb-0 fw-bold fs-4 text-dark">{`${store.months[month]}, ${monthYear}`}</h5>
              <button className="btn btn-secondary" onClick={nextMonth}>
                След
              </button>
            </div>
            <div className="week-calendar-container">
              <table className="table table-bordered my-4 table-excel">
                <thead className="table-dark">
                  <tr>
                    <th colSpan={1} rowSpan={2}>
                      Отдел/Сотрудник
                    </th>
                  </tr>
                  <tr>
                    {monthDays.map((day: Date, index) => (
                      <th className="th-day" key={index}>
                        {day.getDate()}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>{renderUserRowsForDays()}</tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default observer(GeneralTable);
