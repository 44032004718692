import React, { useEffect, useState, useContext } from "react";
import { Form } from "react-bootstrap";
import { Context } from "..";
import { Link, useNavigate, useParams } from "react-router-dom";

const EditAvailableDays = () => {
  const [value, setValue] = useState<number>(0);
  const { store } = useContext(Context);
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      store.fetchPeriod(id).then(() => {
        setValue(store.period.availableHolidayDays);
      });
    }
  }, [id, store]);

  const handleSubmit = () => {
    if (id) {
      store.editAvailableDays(id, value)
      navigate(`/users`);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(e.target.value));
    console.log("changed" + value);
  };
  return (
    <div className="container">
      <h2>Редактирование доступных дней в периоде</h2>
      <form>
        <Form.FloatingLabel
          label="Количество доступных дней в периоде"
          className="mb-2"
          controlId="floatingInput"
        >
          <Form.Control
            value={value}
            onChange={handleChange}
            required={true}
            type="number"
            name="availableHolidayDays"
          />
        </Form.FloatingLabel>
        <button
          onClick={handleSubmit}
          type="submit"
          className="btn btn-primary btn-block"
        >
          Подтвердить
        </button>
        <Link
          className="btn btn-secondary"
          to="/users"
        >
          Отмена
        </Link>
      </form>
    </div>
  );
};

export default EditAvailableDays;
