import React, { useContext, useEffect, useState } from "react";
import { Context } from "..";
import Spinner from "../components/Spinner";
import { observer } from "mobx-react-lite";

const Mainpage = () => {
  const { store } = useContext(Context);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function checkAuthentication() {
      if (localStorage.getItem("token")) {
        try {
          await store.checkAuth();
        } catch (e) {
          console.log(e);
        }
      }
      setIsLoading(false);
    }

    checkAuthentication();
  }, [store]);

  if (isLoading) {
    return <Spinner />;
  }

  if (!store.isAuth) {
    return (
      <div className="text-center">
        <h1 className="display-4">Добро пожаловать!</h1>
        <div className="d-flex justify-content-center my-0">
          <div className="border rounded my-0 custom-forms col-md-4 p-3">
            <div className="mx-auto my-2 text-center">
              <p>
                Есть аккаунт? <a href="/login">Вход</a>
              </p>
              <p>
                У вас ещё нет аккаунта?{" "}
                <a href="/register">Зарегистрироваться</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {store.isAuth && (
        <div b-p8argd8r70="" className="container">
          <main b-p8argd8r70="" role="main" className="pb-3">
            <div className="text-center">
              <h1 className="display-4 mb-5">
                Добро пожаловать, {store.user.name} !
              </h1>
              <div className="text-start">
                <p className="ms-3">
                  Данная программа предназначена для того, чтобы Вы смогли
                  самостоятельно выбрать для себя отпуск.
                </p>
                <p className="ms-3">
                  Для того чтобы выбрать отпуск, перейдите в личный кабинет (в
                  правом верхнем углу) и нажмите на кнопку "Выбрать отпуск".
                  Появится форма выбора отпуска. Выберите даты с какого по какое
                  число Вы желаете пойти в отпуск и нажмите кнопку "Подтвердить"
                </p>
                <p className="ms-3">Условия для выбора:</p>
                <ul>
                  <li>Отпуск должен быть кратным неделе</li>
                  <li>
                    Если Вы попадаете в пересечение с кем-то из сотрудников, то
                    ниже увидите уже занятые даты
                  </li>
                  <li>
                    Если Вы ошиблись в датах, Вы можете "Удалить" отпуск и
                    выбрать его снова
                  </li>
                  <li>
                    В одном периоде не может быть более двух отпусков, поэтому
                    если Вы выбрали неделю, а затем выбрали две недели, то
                    оставшаяся неделя будет отложена на следующий период
                  </li>
                  <li>
                    Нельзя выбрать отпуск "в прошлом", внимательно смотрите год
                  </li>
                  <li>
                    Нельзя выбрать отпуск в марте, а также с 1 сентября по 31
                    декабря
                  </li>
                  <li>Нельзя выбрать отпуск более чем на 28 дней</li>
                </ul>
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  );
};

export default observer(Mainpage);
