import React, { useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { IDepartment } from "../models/IDepartment";
import DepartmentService from "../services/DepartmentService";
import { IPosition } from "../models/IPosition";
import PositionService from "../services/PositionService";
import { Context } from "../index";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
interface IError {
  password?: string;
  department?: string;
  position?: string;
  name?: string;
  surname?: string;
}
const RegisterForm = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [department, setDepartment] = useState<IDepartment[]>([]);
  const [position, setPosition] = useState<IPosition[]>([]);
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [selectedDepartment, setSelectedDepartment] = useState<string>("");
  const [selectedPosition, setSelectedPosition] = useState<string>("");

  const [errors, setErrors] = useState<IError>({});

  const { store } = useContext(Context);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const departmentResponse = await DepartmentService.fetchDepartment();
        const positionResponse = await PositionService.fetchPosition();

        console.log(departmentResponse.data);
        console.log(positionResponse.data);

        setDepartment(departmentResponse.data);
        setPosition(positionResponse.data);
      } catch (error) {
        console.error("Ошибка при получении данных: ", error);
      }
    };

    fetchData();
  }, []);

  if (store.isLoading) {
    return (
      <Spinner />
    );
  }

const submitButton = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  const newErrors: IError = {}; 

  if (password.length < 6) {
    newErrors.password = "Ваш пароль должен содержать не менее 6 символов";
  }

  if (selectedDepartment === "") {
    newErrors.department = "Выберите отдел";
  }

  if (selectedPosition === "") {
    newErrors.position = "Выберите должность";
  }

  if (name === "") {
    newErrors.name = "Это поле обязательно";
  }

  if (surname === "") {
    newErrors.surname = "Это поле обязательно";
  }
  // Обновляем объект ошибок
  setErrors(newErrors);

  if (Object.keys(newErrors).length === 0) {

    try {
      await store.registration(
        email,
        password,
        name,
        surname,
        selectedPosition,
        selectedDepartment
      );

      setEmail("");
      setName("");
      setSurname("");
      setPassword("");
      setSelectedDepartment("");
      setSelectedPosition("");

      navigate("/login");
    } catch (error) {

     toast.error("Что то пошло не так");
    }
  }
};



  return (
    <div>
      <Container>
        <div className="d-flex justify-content-center">
          <div className="border rounded mb-4 custom-forms mt-5 mb-0 p-5">
            <div className="mx-auto my-2 text-center">
              <a href="/">
                <h3>Программа отпусков</h3>
              </a>
            </div>
            <h6 className="text-secondary mt-2 mb-4 mx-auto text-center">
              Зарегистрируйтесь, чтобы получить более широкие возможности
              приложения
            </h6>
            <form onSubmit={submitButton}>
              <Form.FloatingLabel
                label="Email"
                className="mb-3"
                controlId="floatingInput"
              >
                <Form.Control
                  required={true}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Email"
                />
              </Form.FloatingLabel>
              <Form.Group>
                <Form.FloatingLabel
                  label="Пароль"
                  className="mb-3"
                  controlId="floatingInput"
                >
                  <Form.Control
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    placeholder="Пароль"
                  />
                  {errors.password && (
                    <small className="d-block invalid-feedback">
                      {errors.password}
                    </small>
                  )}
                </Form.FloatingLabel>
              </Form.Group>
              <Form.FloatingLabel
                label="Имя"
                className="mb-3"
                controlId="floatingInput"
              >
                <Form.Control
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  placeholder="Имя"
                />
              </Form.FloatingLabel>
              <Form.FloatingLabel
                label="Фамилия"
                className="mb-3"
                controlId="floatingInput"
              >
                <Form.Control
                  required
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                  type="text"
                  placeholder="Фамилия"
                />
              </Form.FloatingLabel>
              <Form.Select
                required
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value)}
                className="mb-3"
                aria-label="Default select example"
              >
                <option>Выберите отдел</option>
                {department.map((item) => (
                  <option value={item._id}>{item?.title}</option>
                ))}
              </Form.Select>
              {errors.department && (
                <small className="d-block invalid-feedback mb-3">
                  {errors.department}
                </small>
              )}
              <Form.Select
                required
                value={selectedPosition}
                onChange={(e) => setSelectedPosition(e.target.value)}
                className="mb-3"
                aria-label="Default select example"
              >
                <option>Выберите должность</option>
                {position.map((item) => (
                  <option value={item._id}>{item?.title}</option>
                ))}
              </Form.Select>
              {errors.position && (
                <small className="d-block invalid-feedback mb-3">
                  {errors.position}
                </small>
              )}
              <Button variant="primary" type="submit">
                Зарегистрироваться
              </Button>
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default observer(RegisterForm);
