/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from "react";
import { Context } from "..";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import { Placeholder } from "react-bootstrap";
import { observer } from "mobx-react-lite";

const ControlGroups = () => {
  const { store } = useContext(Context);
  const navigate = useNavigate();
  useEffect(() => {
    store.getGroups();
  }, []);

  const deleteHoliday = async (id: string) => {
    if (window.confirm("Вы уверены, что хотите удалить данную группу?")) {
      await store.deleteGroup(id);
    } else {
      toast.error("Вы отменили удаление");
    }
  };
  if (store.isLoading) {
    return <Spinner />;
  }
  

  return (
    <div className="container">
      <h2>Контрольные группы</h2>
      <table className="table table-striped table-hover">
        <tbody>
          <tr>
            <th>№</th>
            <th>Наименование</th>
            <th>Количество сотрудников</th>
            <th>Управление</th>
          </tr>
          {store.groups.map((el, index) => (
            <tr key={el._id}>
              <th>{index + 1}</th>
              <td>
                <Link to={`/controlGroups/profile/${el._id}`}>{el.title}</Link>
              </td>
              <td>{el.employees?.length}</td>
              <td>
                <Link
                  className="btn btn-warning mx-2"
                  to={`/controlGroups/edit/${el._id}`}
                >
                  Редактировать
                </Link>
                <a
                  className="btn btn-danger"
                  onClick={() => {
                    deleteHoliday(el._id);
                  }}
                >
                  Удалить
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Link className="btn btn-primary" to="/controlGroups/addControlGroup">
        Создать контрольную группу
      </Link>
    </div>
  );
};

export default observer(ControlGroups);
