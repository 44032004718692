import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Context } from "..";
import Placeholder from "./Placeholder";

const Header = () => {
  const { store } = useContext(Context);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function checkAuthentication() {
      if (localStorage.getItem("token")) {
        try {
          await store.checkAuth();
        } catch (e) {
          console.log(e);
        }
      }
      setIsLoading(false);
    }

    checkAuthentication();
  }, [store]);

  if (isLoading) {
    return <Placeholder />;
  }

  return (
    <header>
      <nav
        b-p8argd8r70=""
        className="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3"
      >
        <div b-p8argd8r70="" className="container-fluid">
          <a className="navbar-brand" href="/">
            Программа отпусков
          </a>
          <button
            b-p8argd8r70=""
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target=".navbar-collapse"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span b-p8argd8r70="" className="navbar-toggler-icon"></span>
          </button>
          <div
            b-p8argd8r70=""
            className="navbar-collapse collapse d-sm-inline-flex justify-content-between"
          >
            <ul b-p8argd8r70="" className="navbar-nav flex-grow-1">
              <li b-p8argd8r70="" className="nav-item">
                <Link to={"/"} className="nav-link text-dark">
                  Главная
                </Link>
              </li>
              {store.user.roles?.[0] === "ADMIN" && (
                <li b-p8argd8r70="" className="nav-item">
                  <Link to={"/users"} className="nav-link text-dark">
                    Список сотрудников
                  </Link>
                </li>
              )}
              {store.user.roles?.[0] === "ADMIN" && (
                <li b-p8argd8r70="" className="nav-item">
                  <Link className="nav-link text-dark" to="/controlGroups">
                    Контрольные группы
                  </Link>
                </li>
              )}
              {store.user.roles?.[0] === "ADMIN" && (
                <li b-p8argd8r70="" className="nav-item">
                  <Link className="nav-link text-dark" to="/positions">
                    Должности
                  </Link>
                </li>
              )}
              {store.user.roles?.[0] === "ADMIN" && (
                <li b-p8argd8r70="" className="nav-item">
                  <Link className="nav-link text-dark" to="/departments">
                    Отделы
                  </Link>
                </li>
              )}
              {store.user.isLeader && (
                <li b-p8argd8r70="" className="nav-item">
                  <Link className="nav-link text-dark" to={`/departments/${store.user.department._id}`}>
                    Мой отдел
                  </Link>
                </li>
              )}
              {store.user.roles?.[0] === "ADMIN" && (
                <li b-p8argd8r70="" className="nav-item">
                  <Link className="nav-link text-dark" to="/stateHolidays">
                    Гос.праздники
                  </Link>
                </li>
              )}

              {store.user.roles?.[0] === "ADMIN" && (
                <li b-p8argd8r70="" className="nav-item">
                  <Link className="nav-link text-dark" to="/home/generalTable">
                    Общая таблица
                  </Link>
                </li>
              )}
            </ul>
            <ul
              b-p8argd8r70=""
              className="navbar-nav flex-grow-1 justify-content-end align-items-center"
            >
              <li b-p8argd8r70="" className="nav-item">
                {store.isAuth ? (
                  <Link
                    to={`/profile/${store.user.id}`}
                    className="nav-link text-dark"
                  >
                    Личный кабинет
                  </Link>
                ) : (
                  <a className="nav-link text-dark" href="/login">
                    Войти
                  </a>
                )}
              </li>
              {store.isAuth && (
                <li b-p8argd8r70="" className="nav-item">
                  <Button
                    className="btn-sm btn-dark"
                    onClick={() => store.logout()}
                  >
                    Выход
                  </Button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default observer(Header);
