import React, { useContext, useState, useEffect } from "react";
import { Context } from "..";
import { Link, useParams } from "react-router-dom";
import UserTable from "../components/UserTable";
import { observer } from "mobx-react-lite";
import { Placeholder } from "react-bootstrap";

const Position = () => {
  const { store } = useContext(Context);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      store.fetchPoistionByUser(id);
      store.getPositionById(id);
    }
  }, [id, store]);

  if (store.isLoading) {
    return <Placeholder />;
  }

  return (
    <div className="container">
      <h2>Список сотрудников в на позиции {store.oneposition?.title}</h2>
      <p>Список сотрудников {store.positionByUser?.length}</p>
      <table id="users-table" className="table table-striped table-hover">
        <tbody>
          <tr>
            <th>Фамилия</th>
            <th>Имя</th>
            <th>Email</th>
            <th>Управление</th>
          </tr>
          {store.positionByUser?.map((user) => (
            <tr key={user._id}>
              <td>
                <Link to={`/user/${user._id}`} className="link-primary">
                  {user.surname}
                </Link>
              </td>
              <td>
                <Link to={`/user/${user._id}`} className="link-primary">
                  {user.name}
                </Link>
              </td>
              <td
                className={
                  user.employmentDateChanged ? "text-success" : "text-danger"
                }
              >
                {user.email}
              </td>
              <td>
                <Link to={`/edit/${user._id}`} className="btn bg-warning">
                  Редактировать
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <UserTable users={store?.positionByUser} /> */}
    </div>
  );
};

export default observer(Position);
