import { AxiosResponse } from "axios";
import $api from "../http";
import { IDepartment } from "../models/IDepartment";
import { IUser } from "../models/IUser";
export default class DepartmentService {
  static fetchDepartment(): Promise<AxiosResponse<IDepartment[]>> {
    return $api.get<IDepartment[]>("/departments");
  }

  static fetchDepartmentAndUser(
    departmentId: string
  ): Promise<AxiosResponse<IUser[]>> {
    return $api.get<IUser[]>(`/department/${departmentId}`);
  }

  static fetchOneDepartment(id: string): Promise<AxiosResponse<IDepartment>> {
    return $api.get<IDepartment>(`/departments/${id}`);
  }
}
