import React, { useContext, useEffect, useState } from "react";
import DepartmentService from "../services/DepartmentService";
import { useParams } from "react-router-dom";
import { IDepartment } from "../models/IDepartment";

import { IUser } from "../models/IUser";
import UserTable from "../components/UserTable";
import { Context } from "..";
import Placeholder from "../components/Placeholder";
import { observer } from "mobx-react-lite";
import { Week } from "../models/IWeek";
import { IHolidays } from "../models/IHolidays";

const DepartmentPage = () => {
  const [user, setUser] = useState<IUser[]>([]);
  const [department, setDepartment] = useState<IDepartment>();
  const { id } = useParams();
  const [year, setYear] = useState(new Date().getFullYear() + 1);
  const [monthColspan, setMonthColspan] = useState<any>([]);
  const [weekTitles, setWeekTitles] = useState<Week[]>([]);

  useEffect(() => {
    if (id) {
      store.getGeneralTableForDepartment(id);
    }
  }, [id]);

  useEffect(() => {
    store.renderWeekTitle(year, setWeekTitles, setMonthColspan);
  }, [year]);
  const { store } = useContext(Context);

  useEffect(() => {
    async function fetchDepartmentAndUsers() {
      if (id) {
        try {
          const response = await DepartmentService.fetchDepartmentAndUser(id);
          const response2 = await DepartmentService.fetchOneDepartment(id);
          setUser(response?.data);
          setDepartment(response2?.data);
        } catch (error) {
          console.error("Error fetching department and users:", error);
        }
      }
    }

    fetchDepartmentAndUsers();
  }, [id]);

  if (store.isLoading) {
    <Placeholder />;
  }

  const renderUserWeek = (periods: IHolidays[], weekTitles: Week[]) => {
    return weekTitles.map((week, index) => {
      const { checkStatus, daysRange } = store.checkPeriodInWeek(periods, week);
      console.log(checkStatus && week);
      let isTaboo = store.isMarchOrSeptToJanWeek(week)
      return (
        <td
          key={index}
          className={`vertical ${checkStatus ? "selected colorfy" : "border"} ${isTaboo ? "taboo" : ""}`}
        >
          {daysRange}
        </td>
      );
    });
  };

  const renderUserRows = () => {
    return store.generalTableByDepartment.map((department) => (
      <>
        <tr>
          <th>{department.title}</th>
        </tr>
        {department.users.map((user) => (
          <tr>
            <td>
              {user.name} {user.surname}
            </td>
            {renderUserWeek(user.holidays, weekTitles)}
          </tr>
        ))}
      </>
    ));
  };

  const nextYear = (increment: any) => {
    setYear((currentYear) => currentYear + increment);
  };

  const submitHandler = () => {
    store.exportToExcel(store.getExportData());
  };

  return (
    <>
      <div className="container ">
        <h2>Список сотрудников в отделе {department?.title}</h2>
        <p>Список сотрудников {user?.length}</p>
        <p className="my-4">
          Руководитель {department?.leader?.name} {department?.leader?.surname}
        </p>
        <UserTable users={user} admin={store.user} />
      </div>
      <div className="container mb-5 mt-5">
        <div className="d-flex">
          <h2 className="me-5">Общий график отпусков</h2>
          <button
            onClick={submitHandler}
            className="btn btn-success btn-sm me-2"
          >
            Экспорт в Excel
          </button>
        </div>
        <div className="d-flex week-calendar-header mb-3">
          <button className="btn btn-secondary" onClick={() => nextYear(-1)}>
            Пред
          </button>
          <h5 className="mx-2 fw-bold fs-4">{year}</h5>
          <button className="btn btn-secondary" onClick={() => nextYear(1)}>
            След
          </button>
        </div>
        <div className="week-calendar-container">
          <table className="table table-bordered my-4 table-excel">
            <thead className="table-dark">
              <tr>
                <th colSpan={1} rowSpan={2}>
                  Отдел/Сотрудник
                </th>
                {monthColspan.map((colspan: number, index: number) => (
                  <th className="" key={index} colSpan={colspan}>
                    {store.getMonthName(index)}
                  </th>
                ))}
              </tr>
              <tr>
                {weekTitles.map((week: any, index: number) => (
                  <td colSpan={0} rowSpan={0} key={index} className="vertical">
                    {store.getDateString(week.start)} -{" "}
                    {store.getDateString(week.end)}
                  </td>
                ))}
              </tr>
            </thead>

            <tbody>{renderUserRows()}</tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default observer(DepartmentPage);
