import React, {useContext, useState} from 'react';
import { Button, Form } from 'react-bootstrap';
import { Context } from '..';

const RecoveryPassword = () => {
  const { store } = useContext(Context)
  const [email, setEmail] = useState<string>("");
  const sendHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    store.sendToRecovery(email);
  };

 

  return (
    <div className="container">
      <div className="d-flex justify-content-center">
        <div className="border rounded mb-4 custom-forms mt-5 mb-0 p-5">
          <div className="mx-auto my-2 text-center">
            <h1>Восстановление пароля</h1>
          </div>
          <h6 className="text-secondary mt-2 mb-4 mx-auto text-center">
            Напишите ваш почтовый адрес и мы отправим вам ссылку для
            восстановление
          </h6>
          <form onSubmit={sendHandler}>
            <Form.FloatingLabel
              label="Email"
              className="mb-3"
              controlId="floatingInput"
            >
              <Form.Control
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </Form.FloatingLabel>
            <Button variant="primary" type="submit">
              Сбросить пароль
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RecoveryPassword;