import React, {useState, useContext} from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "..";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const {store} = useContext(Context)
  const {id} = useParams()
  const navigate = useNavigate()
  const sendHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
       store.recoverPassword(newPassword, id, navigate);
    } else {
      toast.error("Пароли не совпадают")
    }
  };
  

  return (
    <div className="container">
      <div className="d-flex justify-content-center">
        <div className="border rounded mb-4 custom-forms mt-5 mb-0 p-5">
          <div className="mx-auto my-2 text-center">
            <h1>Восстановление пароля</h1>
          </div>
          <h6 className="text-secondary mt-2 mb-4 mx-auto text-center">
            Напишите ваш почтовый адрес и мы отправим вам ссылку для
            восстановление
          </h6>
          <form onSubmit={sendHandler}>
            <Form.FloatingLabel
              label="Новый пароль"
              className="mb-3"
              controlId="floatingInput"
            >
              <Form.Control
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                type="password"
              />
            </Form.FloatingLabel>
            <Form.FloatingLabel
              label="Подтвердите пароль"
              className="mb-3"
              controlId="floatingInput"
            >
              <Form.Control
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                type="password"
              />
            </Form.FloatingLabel>
            <Button variant="primary" type="submit">
              Сохранить
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
