/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeAutoObservable } from "mobx";
import { IUser } from "../models/IUser";
import AuthService from "../services/AuthService";
import axios, { AxiosError } from "axios";
import { AuthResponse } from "../models/response/AuthResponse";
import $api, { API_URL } from "../http";
import { IDepartment } from "../models/IDepartment";
import { toast } from "react-toastify";
import { IPeriod } from "../models/IPeriod";
import { IHolidays } from "../models/IHolidays";
import { IGroups } from "../models/IGroups";
import { IStateHoliday } from "../models/IStateHoliday";
import { IGeneralTable } from "../models/IGeneralTable";
import { IPosition } from "../models/IPosition";
import { Week } from "../models/IWeek";
import { IRestrictedHolidays } from "../models/IRestrcitedHolidays";
import UserService from "../services/UserService";
export default class Store {
  user = {} as IUser;
  isAuth = false;
  isLoading = false;
  period = {} as IPeriod;
  department = [] as IDepartment[];
  positions = [] as IPosition[];
  roles = [];
  userHolidays = [] as IHolidays[];
  groups = [] as IGroups[];
  group = {} as IGroups;
  onedepartment = {} as IDepartment;
  stateHolidays = [] as IStateHoliday[];
  generalTable = [] as IGeneralTable[];
  oneposition = {} as IPosition;
  positionByUser = [] as IUser[];
  generalTableByDepartment = [] as IGeneralTable[];
  users = [] as IUser[];
  oneuser = {} as IUser;
  constructor() {
    makeAutoObservable(this);
  }

  setAuth(bool: boolean) {
    this.isAuth = bool;
  }

  setOneUser(oneuser: IUser) {
    this.oneuser = oneuser;
  }

  setUsers(users: IUser[]) {
    this.users = users;
  }

  setGeneralTable(generalTable: IGeneralTable[]) {
    this.generalTable = generalTable;
  }

  setOneGeneralTable(generalTableByDepartment: IGeneralTable[]) {
    this.generalTableByDepartment = generalTableByDepartment;
  }

  setGroups(groups: IGroups[]) {
    this.groups = groups;
  }

  setPositions(positions: IPosition[]) {
    this.positions = positions;
  }

  setPositionByUser(positionByUser: IUser[]) {
    this.positionByUser = positionByUser;
  }

  setOnePosition(oneposition: IPosition) {
    this.oneposition = oneposition;
  }

  setStateHoliday(stateHolidays: IStateHoliday[]) {
    this.stateHolidays = stateHolidays;
  }

  setGroup(group: IGroups) {
    this.group = group;
  }

  setUser(user: IUser) {
    this.user = user;
  }

  setPeriod(period: IPeriod) {
    this.period = period;
  }

  setDepartment(department: IDepartment[]) {
    this.department = department;
  }

  setOneDepartment(onedepartment: IDepartment) {
    this.onedepartment = onedepartment;
  }

  setLoading(bool: boolean) {
    this.isLoading = bool;
  }

  setRoles(roles: []) {
    this.roles = roles;
  }

  setUserHolidays(userHolidays: IHolidays[]) {
    this.userHolidays = userHolidays;
  }

  renderWeekTitle(year: number, setWeekTitles: any, setMonthColspan: any) {
    let date = new Date(year, 0, 1);
    let lastDayOnWeek = new Date(year, 0, 2);
    let newMonthColspan = Array(12).fill(0);
    let newWeekTitles = [];

    if (date.getDay() === 0) {
      date.setDate(date.getDate() + 1);
      lastDayOnWeek.setDate(lastDayOnWeek.getDate() + 6);
    } else if (date.getDay() >= 5) {
      while (date.getDay() !== 1) {
        date.setDate(date.getDate() + 1);
        lastDayOnWeek.setDate(lastDayOnWeek.getDate() + 1);
      }
      while (lastDayOnWeek.getDay() !== 0) {
        lastDayOnWeek.setDate(lastDayOnWeek.getDate() + 1);
      }
    } else {
      while (lastDayOnWeek.getDay() !== 0) {
        lastDayOnWeek.setDate(lastDayOnWeek.getDate() + 1);
      }
    }

    while (date < new Date(year + 1, 0, 1)) {
      newWeekTitles.push({
        start: new Date(date),
        end: new Date(lastDayOnWeek),
      });
      newMonthColspan[date.getMonth()]++;
      date.setDate(date.getDate() + 7);
      lastDayOnWeek.setDate(lastDayOnWeek.getDate() + 7);
    }

    setWeekTitles(newWeekTitles);
    setMonthColspan(newMonthColspan);
  }

  isMarchOrSeptToJanWeek = (week: Week) => {
    const startMonth = week.start.getMonth();
    const endMonth = week.end.getMonth();

    const isMarch = startMonth === 2 || endMonth === 2;
    const isSeptToDec =
      (startMonth >= 8 && startMonth <= 11) ||
      (endMonth >= 8 && endMonth <= 11);

    return isMarch || isSeptToDec;
  };

  renderMonthDays = (year: number, month: number, setMonthDays: any) => {
    let startDate = new Date(year, month, 1);
    let endDate = new Date(year, month + 1, 0); // Последний день месяца

    let days = [];
    for (
      let date = startDate;
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      days.push(new Date(date));
    }

    setMonthDays(days);
  };
  getExportData(startDate?: string, endDate?: string) {
    const tableElement = document.getElementsByClassName("table-excel")[0];
    console.log(tableElement.children[1].children);

    const table = {
      thead: Array.from(tableElement.children[0].children).map((tr) => {
        return Array.from(tr.children).map((td) => {
          return {
            rowspan: parseInt(td.getAttribute("rowspan") ?? "0"),
            colspan: parseInt(td.getAttribute("colspan") ?? "1"),
            value: td.innerHTML,
            classes: Array.from(td.classList),
          };
        });
      }),
      tbody: Array.from(tableElement.children[1]?.children).map((tr) => {
        return Array.from(tr.children).map((td) => {
          return {
            rowspan: parseInt(td.getAttribute("rowspan") ?? "0"),
            colspan: parseInt(td.getAttribute("colspan") ?? "0"),
            value: td.innerHTML,
            classes: Array.from(td.classList),
          };
        });
      }),
    };
    const exportData = {
      model: table,
      // Дополнительные поля для дат, если они предоставлены
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
    };

    if (startDate && endDate) {
      return exportData;
    } else {
      return table;
    }
  }
  months = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];
  getMonthName = (month: number): string => {
    return this.months[month];
  };

  getDateString = (date: Date) => {
    return `${date.getDate().toString().padStart(2, "0")}.${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}`;
  };

  checkPeriodInWeek(
    periods: IHolidays[],
    week: Week
  ): { checkStatus: boolean; daysRange: string } {
    let checkStatus = false;
    let daysRange = ``;

    for (const period of periods) {
      const startPeriod = new Date(period.dateFrom).getTime();
      const endPeriod = new Date(period.dateUntil).getTime();
      const startWeek = week.start.getTime();
      const endWeek = week.end.getTime();

      // Проверяем пересечение периода и недели
      if (startPeriod <= endWeek && endPeriod >= startWeek) {
        const startOverlap = Math.max(startPeriod, startWeek);
        const endOverlap = Math.min(endPeriod, endWeek);
        const overlapDays = (endOverlap - startOverlap) / (24 * 60 * 60 * 1000);
        if (overlapDays >= 1) {
          checkStatus = true;
          daysRange = `${new Date(startPeriod).getDate()}-${new Date(
            endPeriod
          ).getDate()}`;
        }
        break; // Выходим из цикла после первого найденного пересечения
      }
    }

    return { checkStatus, daysRange };
  }

  formatDateDDMMYYYY(dateString: string) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  async login(email: string, password: string, navigate: any) {
    this.setLoading(true);
    try {
      const response = await AuthService.login(email, password);

      localStorage.setItem("token", response.data.accessToken);
      this.setAuth(true);
      this.setUser(response.data.user);
      console.log(response.data.user);

      toast.success("Вы успешно вошли в аккаунт");
      navigate("/");

      this.setLoading(false);
    } catch (e: any) {
      toast.error(e.response?.data.message);
      console.log(e.response?.data.message);

      this.setLoading(false);
    }
  }

  async registration(
    email: string,
    password: string,
    name: string,
    surname: string,
    position: string,
    department: string
  ) {
    this.setLoading(true);
    try {
      const response = await AuthService.registration(
        email,
        password,
        name,
        surname,
        position,
        department
      );
      toast.success("Вы успешно зарегистрировались!");
      localStorage.setItem("token", response.data.accessToken);
      this.setLoading(false);
      // this.setAuth(true);
      // this.setUser(response.data.user);
    } catch (e) {
      console.log(e);
      this.setLoading(false);
    }
  }

  async logout() {
    this.setLoading(true);
    try {
      const response = await AuthService.logout();
      localStorage.removeItem("token");
      this.setAuth(false);
      this.setUser({} as IUser);

      window.location.href = "/";

      this.setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  async changePassword(
    userId: string,
    newPassword: string,
    oldPassword: string
  ) {
    this.setLoading(true);
    try {
      const response = await AuthService.changePassword(
        userId,
        oldPassword,
        newPassword
      );
      this.logout();
    } catch (e: any) {
      toast.error(e);
    }
  }

  async sendToRecovery(email: string) {
    this.setLoading(true);
    try {
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/send-reset-password`, {
          email: email,
        })
        .then(() => {
          toast.success("Сообщение успешно отправлено");
        });

      this.setLoading(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
      this.setLoading(false);
    }
  }

  async recoverPassword(password: string, token?: string, navigate?: any) {
    this.setLoading(true);
    let data = {
      newPassword: password,
      token: token,
    };
    try {
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/reset-password`, data)
        .then(() => {
          toast.success("Вы успешно восстановили пароль");
          navigate("/login");
        });

      this.setLoading(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
      this.setLoading(false);
    }
  }

  async checkAuth() {
    this.setLoading(true);
    try {
      const response = await axios.get<AuthResponse>(
        `${process.env.REACT_APP_API_URL}/refresh`,
        {
          withCredentials: true,
        }
      );
      console.log(response.data.user);

      localStorage.setItem("token", response.data.accessToken);
      this.setAuth(true);
      this.setUser(response.data.user);

      this.setLoading(false);
    } catch (e) {
      console.log(e);
      this.setLoading(false);
    }
  }
  async addUsers(data: any, navigate: any) {
    try {
      const response = await $api
        .post(`${process.env.REACT_APP_API_URL}/upload-csv`, data)
        .then(() => {
          toast.success("Вы успешно импортировали пользователей");
          this.fetchUsers();
          setTimeout(() => {
            navigate("/users");
          }, 1000);
        });
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
    }
  }
  async fetchDepartments() {
    try {
      const response = await $api.get(
        `${process.env.REACT_APP_API_URL}/departments`
      );
      console.log(response.data);
      this.setDepartment(response.data);
    } catch (e) {
      console.log(e);
    }
  }

  async editUsers(updatedUser: IUser, id: string) {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/${id}`,
        updatedUser
      );
    } catch (e) {
      console.log(e);
    }
  }

  async editAvailableDays(id: string, days: number) {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/periods/edit/${id}`,
        { availableHolidayDays: days }
      );
    } catch (e) {
      console.log(e);
    }
  }

  async fetchPeriod(id: string) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/periods/${id}`
      );
      this.setPeriod(response.data);
    } catch (e) {
      console.log(e);
    }
  }

  async createHolidays(data: IHolidays) {
    this.setLoading(true);
    try {
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/holidays`, data)
        .then(() => {
          toast.success("Вы успешно создали отпуск");
        });

      this.setLoading(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
      console.log(e?.response?.data?.message);

      this.setLoading(false);
    }
  }

  async deleteHolidays(id: string) {
    this.setLoading(true);
    try {
      const response = await axios
        .delete(`${process.env.REACT_APP_API_URL}/holidays/${id}`)
        .then(() => {
          toast.success("Вы успешно удалили отпуск");
        });
      this.fetchUser(id);
      this.setLoading(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
      this.setLoading(false);
    }
  }

  async fetchUser(id: string) {
    this.setLoading(true);
    try {
      const response = await UserService.fetchUser(id);
      this.setOneUser(response.data);
      this.setLoading(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
      this.setLoading(false);
    }
  }

  async getHolidaysById(id: string) {
    this.setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/holidays/department/${id}`
      );
      this.setUserHolidays(response.data);
      this.setLoading(false);
      console.log(response.data);
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
      this.setLoading(false);
    }
  }

  async getGroups() {
    this.setLoading(true);
    try {
      const response = await $api.get(
        `${process.env.REACT_APP_API_URL}/controlGroups/`
      );
      this.setGroups(response.data);
      this.setLoading(false);
      console.log(response.data);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ||
          "Произошла ошибка при получении данных группы"
      );
      this.setLoading(false);
    }
  }

  async getGroupById(id: string) {
    this.setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/controlGroups/${id}`
      );
      this.setGroup(response.data);
      this.setLoading(false);
      console.log(response.data);
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
      this.setLoading(false);
    }
  }

  async editGroup(id: string, name: string) {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/controlGroups/edit/${id}`,
        { title: name }
      );
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
      console.log(e);
    }
  }

  async deleteGroup(id: string) {
    this.setLoading(true);
    try {
      const response = await axios
        .delete(`${process.env.REACT_APP_API_URL}/controlGroups/${id}`)
        .then(() => {
          toast.success("Вы успешно удалили группу");
          this.getGroups();
        });
      this.setLoading(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
      this.setLoading(false);
    }
  }

  async addUserByGroup(id?: string, userId?: string) {
    const data = { groupId: id, userId: userId };
    this.setLoading(true);
    try {
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/controlGroups/addUser`, data)
        .then(() => {
          toast.success("Вы успешно добавили пользователя");
        });

      this.setLoading(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
      console.log(e?.response?.data?.message);

      this.setLoading(false);
    }
  }

  async deleteUserByGroup(groupId?: string, userId?: string) {
    try {
      const response = await axios
        .delete(
          `${process.env.REACT_APP_API_URL}/controlGroups/${groupId}/user/${userId}`
        )
        .then(() => {
          toast.success("Вы успешно удалили группу");
        });
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
      console.log(e?.response?.data?.message);

      this.setLoading(false);
    }
  }

  async getStateHolidays() {
    this.setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/stateHolidays/`
      );
      this.setStateHoliday(response.data);
      this.setLoading(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
      this.setLoading(false);
    }
  }

  async deleteStateHoliday(id?: string) {
    try {
      const response = await axios
        .delete(`${process.env.REACT_APP_API_URL}/stateHolidays/${id}`)
        .then(() => {
          toast.success("Вы успешно удалили группу");
        });
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
    }
  }

  async addStateHoliday(data: IStateHoliday) {
    try {
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/stateHolidays`, data)
        .then(() => {
          toast.success("Вы успешно добавили праздник");
        });
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
    }
  }

  async addControlGroup(data: object) {
    try {
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/controlGroups`, data)
        .then(() => {
          toast.success("Вы успешно добавили контрольную группу");
        });
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
    }
  }

  async getGeneralTable() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getUsersForGeneralCalendar`
      );
      this.setGeneralTable(response.data);
      console.log(response.data);
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
    }
  }

  async getGeneralTableForDepartment(id: string) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getUsersForGeneralCalendar/${id}`
      );
      this.setOneGeneralTable(response.data);
      console.log(response.data);
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
    }
  }

  async exportToExcel(data: any) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/holidays/exportToExcel`,
        data,
        { responseType: "blob" }
      );

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "Общая таблица.xlsx"); // Имя файла для скачивания
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      toast.error("Ошибка");
    }
  }

  async exportToExcelByPeriod(data: any) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/holidays/exportToExcelByPeriod`,
        data,
        { responseType: "blob" }
      );

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "Общая таблица.xlsx"); // Имя файла для скачивания
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      toast.error("Ошибка");
    }
  }

  async addDepartment(data: IDepartment, navigate: any) {
    try {
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/departments`, data)
        .then(() => {
          toast.success("Вы успешно добавили отдел");
          navigate("/departments");
          this.fetchDepartments();
        });
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
    }
  }

  async deleteDepartment(id?: string) {
    try {
      const response = await axios
        .delete(`${process.env.REACT_APP_API_URL}/departments/${id}`)
        .then(() => {
          toast.success("Вы успешно удалили группу");
        });
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
    }
  }

  async editDepartment(id: string, name?: string) {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/departments/edit/${id}`,
        { title: name }
      );
    } catch (e) {
      console.log(e);
    }
  }

  async getDepartmentById(id: string) {
    this.setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/departments/${id}`
      );
      this.setOneDepartment(response.data);
      this.setLoading(false);
      console.log(response.data);
    } catch (e: any) {
      toast.error(e);
      this.setLoading(false);
    }
  }

  async fetchPositions() {
    try {
      const response = await $api.get(
        `${process.env.REACT_APP_API_URL}/positions`
      );
      console.log(response.data);
      this.setPositions(response.data);
      this.setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  async deletePosition(id?: string) {
    try {
      const response = await axios
        .delete(`${process.env.REACT_APP_API_URL}/positions/${id}`)
        .then(() => {
          toast.success("Вы успешно удалили должность");
        });
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
    }
  }

  async addPosition(data: IPosition, navigate: any) {
    try {
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/positions`, data)
        .then(() => {
          navigate("/positions");
          this.fetchPositions();
        });
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
    }
  }

  async editPosition(id: string, name?: string) {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/positions/edit/${id}`,
        { title: name }
      );
    } catch (e: any) {
      console.log(e?.response?.data?.message);
    }
  }

  async getPositionById(id: string) {
    this.setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/positions/${id}`
      );
      this.setOnePosition(response.data);
      this.setLoading(false);
      console.log(response.data);
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
      this.setLoading(false);
    }
  }

  async fetchPoistionByUser(id: string) {
    this.setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/position/${id}`
      );
      this.setPositionByUser(response.data);

      this.setLoading(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
      this.setLoading(false);
    }
  }

  async editRestrictedDays(id: string, data: IRestrictedHolidays) {
    try {
      const response = await axios
        .put(
          `${process.env.REACT_APP_API_URL}/department/${id}/restricted-holidays`,
          { restrictedPeriods: [data] }
        )
        .then(() => {
          toast.success("Вы успешно добавили ограничение");
        });
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
    }
  }

  async deleteRestrictedDays(id?: string, data?: string[]) {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/department/${id}/restricted-days`,
        { data: { restrictedDayIds: data } }
      );
      if (id) {
        this.getDepartmentById(id);
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
    }
  }

  async deleteUser(id: string) {
    try {
      const response = await axios
        .delete(`${process.env.REACT_APP_API_URL}/users/${id}`)
        .then(() => {
          toast.success("Вы успешно удалили пользователя");
          this.fetchUsers();
        });
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
    }
  }

  async fetchUsers() {
    this.setLoading(true);
    try {
      const response = await UserService.fetchUsers();
      this.setUsers(response.data);
      this.setLoading(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
      this.setLoading(false);
    }
  }
}
