import { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IUser } from "../models/IUser";
import UserService from "../services/UserService";
import { observer } from "mobx-react-lite";
import { Context } from "..";
import { toast } from "react-toastify";
import ProfileTable from "../components/ProfileTable";
import Spinner from "../components/Spinner";
const UserPage = () => {

  const { store } = useContext(Context);
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      store.fetchUser(id)
    }
  }, [id, store]);

  const deleteHoliday = async (id: string) => {
    if (window.confirm("Вы уверены, что хотите удалить данный отпуск?")) {
      await store.deleteHolidays(id).then(() => {
        navigate(0)
      });

    } else {
      toast.error("Вы отменили удаление");
    }
  };
  
  if (store.isLoading) {
    return <Spinner />
  }
  
  return (
    <>
      {store.oneuser && (
        <div className="pb-4">
          <div className="container">
            <h2>
              {store.oneuser?.name} {store.oneuser?.surname}
            </h2>
            <p>{store.oneuser?.position?.title}</p>
            <ProfileTable user={store.oneuser} deleteHoliday={deleteHoliday} />
            <a
              className="btn btn-primary"
              href={`/holidays/chooseholiday/${store.oneuser._id}`}
            >
              Установить отпуск как админ
            </a>
            <Link
              className="btn btn-info mx-2"
              to={`/periods/${store.oneuser._id}`}
            >
              Редактировать периоды
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default observer(UserPage);
