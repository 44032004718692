import React, { useEffect, useContext } from "react";
import { Context } from "..";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import Spinner from "../components/Spinner";

const Departments = () => {
  const { store } = useContext(Context);
  useEffect(() => {
    store.fetchDepartments();
  }, []);

  if (store.isLoading) {
    return <Spinner />;
  }
  return (
    <div className="container">
      <h2>Список отдела</h2>
      <table id="users-table" className="table table-striped">
        <tbody>
          <tr>
            <th>№</th>
            <th>Название</th>
            <th>Количество сотрудников</th>
            <th>Управление</th>
          </tr>
          {store?.department.map((dep, index) => (
            <tr>
              <th>{index + 1}</th>
              <td>
                <Link to={`/departments/${dep._id}`}>{dep.title}</Link>
              </td>
              <td>
                <p>{dep.employeeCount}</p>
              </td>
              <td>
                <Link
                  className="btn btn-warning me-2"
                  to={`/departments/edit/${dep._id}`}
                >
                  Редактировать
                </Link>
                <a
                  onClick={() => {
                    store.deleteDepartment(dep._id);
                  }}
                  className="btn btn-danger"
                  href="/departments"
                >
                  Удалить
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Link className="btn btn-primary" to="/departments/createDepartment">
        Создать отдел
      </Link>
    </div>
  );
};

export default observer(Departments);
