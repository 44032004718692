import React, { useContext, useEffect, useState } from "react";
import { Context } from "..";
import { IUser } from "../models/IUser";
import UserService from "../services/UserService";
import LoginForm from "../components/LoginForm";
import { observer } from "mobx-react-lite";
import Placeholder from "../components/Placeholder";
import UserTable from "../components/UserTable";
import { Link, useNavigate } from "react-router-dom";
const UsersPage = () => {
  const { store } = useContext(Context);

  const navigate = useNavigate()
  useEffect(() => {
    if (localStorage.getItem("token")) {
      store.checkAuth();
    }
    store.fetchUsers()
  }, []);

  const deleteOneUser = async (id: string) => {
    store.deleteUser(id);
  };
  // if (!store.isAuth) {
  //   return <LoginForm />;
  // }

  if (store.isLoading) {
    return (
      <div className="App">
        <div className="container">
          <h2>Список сотрудников</h2>
          <p>
            Всего сотрудников:{" "}
            <p aria-hidden="true" className="placeholder-glow">
              <span className="w-50 placeholder placeholder-lg col-12"></span>
            </p>
          </p>
          <table id="users-table" className="table table-striped">
            <tbody>
              <tr>
                <th>Фамилия</th>
                <th>Имя</th>
                <th>Email</th>
                <th>Управление</th>
              </tr>
              <tr>
                <td>
                  <Placeholder />
                </td>
                <td>
                  <Placeholder />
                </td>
                <td>
                  <Placeholder />
                </td>
                <td>
                  <Placeholder />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  
  return (
    <div className="App">
      <div className="container">
        <h2>Список сотрудников</h2>
        <p>Всего сотрудников: {store.users?.length}</p>
        <UserTable admin={store?.user} deleteUser={deleteOneUser} users={store.users} />
        <Link className="btn btn-primary mb-5" to="/users/createUsers">
          Испортировать CSV
        </Link>
      </div>
    </div>
  );
};

export default observer(UsersPage);
