/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { IUser } from "../models/IUser";
import { Link } from "react-router-dom";
import { Context } from "..";

type ProfileTableProps = {
  user: IUser;
  deleteHoliday: any;
};

const ProfileTable: React.FC<ProfileTableProps> = ({ user, deleteHoliday }) => {
  const { store } = useContext(Context);

  return (
    <table className="table table-striped">
      <tbody>
        <tr>
          <th>Email</th>
          <td>{user?.email}</td>
        </tr>
        <tr>
          <th>Имя фамилия</th>
          <td>
            {user?.name} {user?.surname}
          </td>
        </tr>
        <tr>
          <th>Отдел</th>
          <td>
            {user.isLeader ? (
              <Link to={`/departments/${user?.department?._id}`}>
                {user?.department?.title}
              </Link>
            ) : (
              <>
                <p>{user?.department?.title}</p>
              </>
            )}
            {user.isLeader && <span className="mx-2">Руководитель</span>}
          </td>
        </tr>
        {
          <tr>
            <th>Контрольная группа</th>
            <td>
              {user?.controlGroup?.map((el) => (
                <p key={el._id} className="mb-2">
                  {" "}
                  <p>{el.title}</p>
                </p>
              ))}
            </td>
          </tr>
        }
        <tr>
          <th>Отпуски</th>
          <td>
            <table className="table table-bordered ">
              <tbody>
                <tr>
                  <th>Период</th>
                  <th>Отпуск</th>
                </tr>
                {user.periods
                  ?.filter((period) => {
                    const today = new Date();
                    const endDate = new Date(period.dateUntil);
                    const hasHolidays = user.holidays?.some(
                      (holiday) => holiday.periodId === period._id
                    );

                    
                    // Фильтрация для будущих периодов или периодов с отпусками
                    return endDate >= today || hasHolidays || period.availableHolidayDays > 0;
                  })
                  .map((period) => (
                    <tr key={period._id}>
                      <td>
                        <p>
                          {store.formatDateDDMMYYYY(period.dateFrom)} -{" "}
                          {store.formatDateDDMMYYYY(period.dateUntil)}
                        </p>
                        <p>
                          Остаток дней в периоде: {period.availableHolidayDays}
                        </p>
                      </td>
                      <td>
                        {user.holidays
                          ?.filter((holiday) => holiday.periodId === period._id)
                          .map((holiday) => (
                            <p key={holiday._id}>
                              {store.formatDateDDMMYYYY(holiday.dateFrom)} -{" "}
                              {store.formatDateDDMMYYYY(holiday.dateUntil)}{" "}
                              Дней: ({holiday.days})
                              <a
                                onClick={() => deleteHoliday(holiday._id)}
                                className="text-danger"
                                style={{ marginLeft: "10px" }}
                              >
                                Удалить
                              </a>
                            </p>
                          ))}
                      </td>
                    </tr>
                  ))}
                {/* <td>
                            <p>
                              31.07.2023 - 06.08.2023 Дней: (7)
                              <Link
                                className="text-danger"
                                to="/Holydays/Delete/239?userId=93819549-3bd2-4686-a339-8b283dae0b99"
                              >
                                Удалить
                              </Link>
                            </p>
                          </td> */}
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ProfileTable;
