import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DateSelectArg, DayCellContentArg } from "@fullcalendar/core";
import { Context } from "..";
import Spinner from "../components/Spinner";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";
import ruLocale from "@fullcalendar/core/locales/ru";
import { IRestrictedHolidays } from "../models/IRestrcitedHolidays";
import { toast } from "react-toastify";
const IEvent: IRestrictedHolidays = {
  to: "",
  from: "",
  _id: "",
};
const EditDepartment = () => {
  const { store } = useContext(Context);
  const [value, setValue] = useState<string | undefined>("");
  const [showTable, setShowTable] = useState<boolean>(true);
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const [days, setDays] = useState<IRestrictedHolidays>(IEvent);
  useEffect(() => {
    if (id) {
      store.getDepartmentById(id).then(() => {
        setValue(store.onedepartment?.title);
        console.log(store.onedepartment?.title);
      });
    }
  }, [id, store]);

  // useEffect(() => {
  //   if (localStorage.getItem("token")) {
  //     store.checkAuth();
  //   }
  // }, []);
  
  const handleSubmit = () => {
    if (id) {
      store.editDepartment(id, value);

      // navigate(0);
    }

    if (store.onedepartment._id) {
      store.editRestrictedDays(store.onedepartment._id, days);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };
  if (store.isLoading) {
    return <Spinner />;
  }

  const getDayCellClassNames = (arg: DayCellContentArg) => {
    const currentDate = new Date(arg.date);
    currentDate.setHours(0, 0, 0, 0);
    const date = arg.date;
    const classNames = ["fullcalendar"];
    const isRestricted = store.onedepartment.restrictedHolidays?.some(
      (holiday) => {
        const fromDate = new Date(holiday.from);
        fromDate.setHours(0, 0, 0, 0);
        const toDate = new Date(holiday.to);
        toDate.setHours(23, 59, 59, 999);

        return date >= fromDate && date <= toDate;
      }
    );

    if (isRestricted) {
      classNames.push("red-day"); // Класс для ограниченных дат
      arg.isDisabled = true; // Отключение ячейки
    }

    return classNames;
  };

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    let calendarApi = selectInfo.view.calendar;
    setDays({
      to: selectInfo.endStr,
      from: selectInfo.startStr,
    });
    calendarApi.unselect();
  };

  const changeHandler = (fields: Partial<IRestrictedHolidays>) => {
    setDays((prev) => {
      return { ...prev, ...fields };
    });
  };

  const deleteHandler = async (id: string) => {
    if (window.confirm("Вы уверены, что хотите удалить?")) {
      try {
        await store.deleteRestrictedDays(store.onedepartment._id, [id]);
        toast.success("Вы успешно удалили");
      } catch (error) {
        toast.error("Произошла ошибка при удалении");
      }
    } else {
      toast.info("Вы отменили удаление");
    }
  };

  return (
    <div className="container mb-5">
      <h2>Редактирование группы {store.onedepartment?.title}</h2>
      <div className="mb-5">
        <Form.Check
          inline
          label="Редактировать"
          name="group1"
          type={"radio"}
          id={`inline-radio-1`}
          checked={showTable}
          onChange={() => {
            setShowTable(true);
          }}
        />
        <Form.Check
          inline
          label="Удалить"
          name="group1"
          type="radio"
          id={`inline-radio-1`}
          onChange={() => {
            setShowTable(false);
          }}
          checked={!showTable}
        />
      </div>
      {showTable ? (
        <form>
          <Form.FloatingLabel
            label="Название отдела"
            className="mb-2"
            controlId="floatingInput"
          >
            <Form.Control
              value={value}
              onChange={handleChange}
              required={true}
              type="text"
              name="title"
            />
          </Form.FloatingLabel>
          <Form.FloatingLabel
            label="Дата начала"
            className="mb-2"
            controlId="floatingInput"
          >
            <Form.Control
              onChange={(e) =>
                changeHandler({
                  from: e.target.value,
                })
              }
              // max={maxDate}
              value={days.from}
              type="date"
              name="from"
              // disabled={user.employmentDateChanged ? true : false}
            />
          </Form.FloatingLabel>
          <Form.FloatingLabel
            label="Дата окончания"
            className="mb-2"
            controlId="floatingInput"
          >
            <Form.Control
              onChange={(e) =>
                changeHandler({
                  to: e.target.value,
                })
              }
              value={days.to}
              type="date"
              name="to"
            />
          </Form.FloatingLabel>
          <div className="my-5">
            <p className="mb-2 fs-3">
              Установление нерабочих дней для отпусков в отделе
            </p>
            <FullCalendar
              locale={ruLocale}
              plugins={[multiMonthPlugin, interactionPlugin]}
              initialView="multiMonthYear"
              selectable={true}
              select={handleDateSelect}
              dayCellClassNames={getDayCellClassNames}
            />
          </div>
          <button
            onClick={handleSubmit}
            type="submit"
            className="btn btn-primary btn-block mx-2"
          >
            Подтвердить
          </button>
          <Link className="btn btn-secondary" to="/positions">
            Отмена
          </Link>
        </form>
      ) : (
        <div className="my-5">
          <h2>Нерабочие дни для отпусков в отделе</h2>
          <table className="table table-hover">
            <tbody>
              <tr>
                <td>Дата начала</td>
                <td>Дата окончания</td>
                <td>Управление</td>
              </tr>
              {store.onedepartment.restrictedHolidays?.map((el) => (
                <tr>
                  <td>{store.formatDateDDMMYYYY(el.from)}</td>
                  <td>{store.formatDateDDMMYYYY(el.to)}</td>
                  <td>
                    <Link
                      onClick={() => {
                        deleteHandler(el?._id || "");
                      }}
                      className="text-danger"
                      to={"#"}
                    >
                      Удалить
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default observer(EditDepartment);
