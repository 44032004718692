/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { IUser } from "../models/IUser";
import { Context } from "..";
import UserService from "../services/UserService";
import { Link, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Spinner from "../components/Spinner";

const ControlGroup = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const { store } = useContext(Context);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams();
  useEffect(() => {
    setLoading(true);
    try {
      const fetchUsers = async () => {
        const response = await UserService.fetchUsers();
        setUsers(response.data);
        setLoading(false);
      };
      if (id) {
        store.getGroupById(id);
      }
      fetchUsers();
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }, []);

  const addUserToGroup = (userId: string) => {
    store.addUserByGroup(id, userId).then(() => {
      navigate(0);
    });
  };

  const deleteUserByGroup = (userId: string) => {
    store.deleteUserByGroup(id, userId).then(() => {
      navigate(0);
    });
  };
  const usersNotInGroup = users.filter(
    (user) =>
      !store.group.employees?.some((employee) => employee._id === user._id)
  );

  if (loading) {
    return <Spinner />
  }
  
  return (
    <div className="container">
      <h2>{store.group.title}</h2>
      {store.group.employees?.length > 0 ? (
        <table className="table table-striped table-hover">
          <tbody>
            <tr>
              <th>Имя</th>
              <th>Фамилия</th>
              <th>Управление</th>
            </tr>

            {store.group.employees?.map((el) => (
              <tr>
                {" "}
                <td>
                  <Link to={`/user/${el._id}`}>{el.name}</Link>
                </td>
                <td>{el.surname}</td>
                <td>
                  <a
                    onClick={() => {
                      deleteUserByGroup(el._id);
                    }}
                    className="btn btn-danger btn-sm"
                  >
                    Удалить
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Сотрудников пока не добавлено</p>
      )}
      <div>
        <a className="btn btn-secondary my-2" href="/controlGroups">
          Назад
        </a>
        <p className="fs-4 fw-bold">Добавить сотрудников:</p>
        {usersNotInGroup.map((user) => (
          <p
            className=""
            onClick={() => {
              addUserToGroup(user._id);
            }}
          >
            <a href="#">
              {user.name} {user.surname}
            </a>
          </p>
        ))}
      </div>
    </div>
  );
};

export default observer(ControlGroup);
