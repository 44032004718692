import React, { useState, useEffect, useContext } from "react";
import { IUser } from "../models/IUser";
import { useParams } from "react-router-dom";
import UserService from "../services/UserService";
import { IPeriod } from "../models/IPeriod";
import { Context } from "..";
import { observer } from "mobx-react-lite";

const PeriodPage = () => {
  const [user, setUser] = useState<IUser>();
  const { store } = useContext(Context);
  
  const { id } = useParams();
  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (id) {
          const response = await UserService.fetchUser(id);
          setUser(response.data);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchUser();
  }, []);

  return (
    <div className="container">
      <h2>Периоды пользователя {user?.name} {user?.surname}</h2>
      <table className="table table-striped table-hover">
        <tbody>
          <tr>
            <th>Дата начала периода</th>
            <th>Дата окончания периода</th>
            <th>Доступное количество дней</th>
            <th>Управление</th>
          </tr>
          {user?.periods?.map((item: IPeriod) => (
            <tr key={item._id}>
              <td>{store.formatDateDDMMYYYY(item.dateFrom)}</td>
              <td>{store.formatDateDDMMYYYY(item.dateUntil)}</td>
              <td>{item.availableHolidayDays}</td>
              <td>
                <a
                  className="btn btn-warning"
                  href={`/periods/edit/${item._id}`}
                >
                  Редактировать
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default observer(PeriodPage);
