import React, { useContext, useEffect } from "react";
import { Context } from "..";

import { Link } from "react-router-dom";
import Spinner from "../components/Spinner";
import { observer } from "mobx-react-lite";

const Position = () => {
  const { store } = useContext(Context);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      store.checkAuth(); 
    }
   
      store.fetchPositions();

  }, [store]);
  
  if (store.isLoading) {
    return <Spinner />;
  }
  return (
    <div className="container">
      <h2>Список должностей</h2>
      <table id="users-table" className="table table-striped">
        <tbody>
          <tr>
            <th>№</th>
            <th>Название</th>
            <th>Количество сотрудников</th>
            <th>Управление</th>
          </tr>
          {store?.positions.map((pos, index) => (
            <tr key={pos._id}>
              <th>{index + 1}</th>
              <td>
                <Link to={`/positions/${pos._id}`}>{pos.title}</Link>
              </td>
              <td>
                <p>{pos.employeeCount}</p>
              </td>
              <td>
                <Link
                  className="btn btn-warning me-2"
                  to={`/positions/edit/${pos._id}`}
                >
                  Редактировать
                </Link>
                <a
                  onClick={() => {
                    store.deletePosition(pos._id);
                  }}
                  className="btn btn-danger"
                  href="/positions"
                >
                  Удалить
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Link className="btn btn-primary" to="/positions/createPosition">
        Создать должность
      </Link>
    </div>
  );
};

export default observer(Position);
