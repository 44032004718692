import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Context } from "..";
import Spinner from "../components/Spinner";

const EditPositions = () => {
  const { store } = useContext(Context);
  const [value, setValue] = useState<string | undefined>("");
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      store.getPositionById(id).then(() => {
        setValue(store.oneposition?.title);
      });
    }
  }, [id, store]);

  const handleSubmit = () => {
    if (id) {
      store.editPosition(id, value);
      navigate(0);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };
  if (store.isLoading) {
    return <Spinner />;
  }
  return (
    <div className="container">
      <h2>Редактирование группы {store.oneposition?.title}</h2>
      <form>
        <Form.FloatingLabel
          label="Название контрольной группы"
          className="mb-2"
          controlId="floatingInput"
        >
          <Form.Control
            value={value}
            onChange={handleChange}
            required={true}
            type="text"
            name="title"
          />
        </Form.FloatingLabel>
        <button
          onClick={handleSubmit}
          type="submit"
          className="btn btn-primary btn-block mx-2"
        >
          Подтвердить
        </button>
        <Link className="btn btn-secondary" to="/positions">
          Отмена
        </Link>
      </form>
    </div>
  );
};

export default observer(EditPositions);
