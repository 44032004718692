import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Context } from '..';

const EditControlGroup = () => {
  const [value, setValue] = useState<string>("");
  const { store } = useContext(Context);
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      store.getGroupById(id).then(() => {
        setValue(store.group.title);
      });
    }
  }, [id, store]);

  const handleSubmit = () => {
    if (id) {
      store.editGroup(id, value);
      navigate(0)
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);

  };

  return (
    <div className="container">
      <h2>Редактирование группы {store.group.title}</h2>
      <form>
        <Form.FloatingLabel
          label="Название контрольной группы"
          className="mb-2"
          controlId="floatingInput"
        >
          <Form.Control
            value={value}
            onChange={handleChange}
            required={true}
            type="text"
            name="title"
          />
        </Form.FloatingLabel>
        <button
          onClick={handleSubmit}
          type="submit"
          className="btn btn-primary btn-block mx-2"
        >
          Подтвердить
        </button>
        <Link className="btn btn-secondary" to="/controlGroups">
          Отмена
        </Link>
      </form>
    </div>
  );
};

export default EditControlGroup;