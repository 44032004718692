import React from 'react';

const NoAccess = () => {
  return (
    <div className="container">
      <h2>Пожалуйста, обратитесь к администратору для получения доступа</h2>
      <a href='/'>Перейти на главную страницу</a>
    </div>
  );
};

export default NoAccess;