import React, {useState, useContext} from 'react';
import { Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Context } from '..';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';

const AddUsers = () => {
  const [file, setFile] = useState<File | null>(null);
  const {store} = useContext(Context)
  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };
  const navigate = useNavigate()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!file) {
      toast.warn("Пожалуйста, выберите файл для загрузки.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    store.addUsers(formData, navigate);
    
  };
  return (
    <div className="container">
      <h2>Добавление пользователей</h2>
      <form onSubmit={handleSubmit}>
        <Form.FloatingLabel
          label="Импортировать csv файл"
          className="mb-2"
          controlId="floatingInput"
        >
          <Form.Control
            onChange={handleFileChange}
            name="file"
            required={true}
            type="file"
          />
        </Form.FloatingLabel>
        <button type="submit" className="btn btn-primary btn-block me-2 my-2">
          Подтвердить
        </button>
        <Link className="btn btn-secondary" to="/users">
          Отмена
        </Link>
      </form>
    </div>
  );
};

export default observer(AddUsers);